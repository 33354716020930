import {
  InfoCircledIcon,
  LockClosedIcon,
  Pencil2Icon,
  QuestionMarkCircledIcon,
  ReaderIcon,
} from "@radix-ui/react-icons"
import { initiateClient } from "client-theme"

import BookLogo from "./components/BookLogo"
import clientTranslations, { TClientTranslationType } from "./translations"

import "./styles.scss"

initiateClient<TClientTranslationType>({
  brand: {
    logos: {
      header: <BookLogo viewBox="350 250 600 300" className="h-12 w-12" />,
      notFound: <BookLogo viewBox="450 250 400 300" width={200} height={200} />,
    },
    buttons: {
      primary: {
        rounded: "full",
      },
      secondary: {},
    },
  },
  clientNamespace: "book",
  clientTranslations,
  externalRoutes: {
    faq: "https://help.book.io/knowledge/book-pool",
    protocol: "https://book.io/stakepool/",
  },
  quickMenuPopover: (currentLocale) => [
    {
      title: clientTranslations[currentLocale].quickMenu.about,
      icon: <InfoCircledIcon />,
      link: "https://book.io/about-us/",
    },
    {
      title: clientTranslations[currentLocale].quickMenu.contact,
      icon: <Pencil2Icon />,
      link: "https://book.io/contact-us/",
    },
    {
      title: clientTranslations[currentLocale].quickMenu.support,
      icon: <QuestionMarkCircledIcon />,
      link: "https://help.book.io/knowledge/book-pool",
    },
    {
      title: clientTranslations[currentLocale].quickMenu.privacy,
      icon: <LockClosedIcon />,
      link: "https://book.io/privacy-policy/",
    },
    {
      title: clientTranslations[currentLocale].quickMenu.terms,
      icon: <ReaderIcon />,
      link: "https://book.io/terms-of-use/",
    },
  ],
  includeClaimingFee: false,
})
