import { ThemeTypes } from "client-theme"
import en from "./en.json"

export type TClientTranslationType = ThemeTypes.TClientTranslation<typeof en>

const translations: Record<string, ThemeTypes.TClientTranslation<typeof en>> = {
  en,
}

export default translations
