import { memo } from "react"

function BookLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlSpace="preserve"
      viewBox="0 0 1366 768"
      width="100%"
      height="100%"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <path
        style={{
          fill: "#11e4ea",
          strokeWidth: "0px",
        }}
        d="M601.2,339.5c.2-.5.4-1.1.5-1.7.2-1.2.2-2.4,0-3.5-.1-.6-.3-1.2-.5-1.7-.2-.5-.5-1.1-.8-1.5-.3-.5-.7-1-1.1-1.4-.4-.4-.9-.8-1.4-1.1-.5-.3-1-.6-1.6-.8-.5-.2-1.1-.4-1.7-.5-.6-.1-1.2-.2-1.8-.2s-1.2,0-1.8.2c-.6.1-1.1.3-1.7.5-.5.2-1.1.5-1.6.8-.5.3-.9.7-1.4,1.1-.4.4-.8.9-1.1,1.4-.3.5-.6,1-.8,1.5-.2.6-.4,1.1-.5,1.7-.1.6-.2,1.2-.2,1.8s0,1.2.2,1.8c.1.6.3,1.1.5,1.7.2.6.5,1.1.8,1.6.3.5.7.9,1.1,1.4.4.4.9.8,1.4,1.1.5.3,1,.6,1.6.8.5.2,1.1.4,1.7.5.6.1,1.2.2,1.8.2s1.2,0,1.8-.2c.6-.1,1.1-.3,1.7-.5.5-.2,1.1-.5,1.6-.8.5-.3.9-.7,1.4-1.1.4-.4.8-.9,1.1-1.4.3-.5.6-1,.8-1.6Z"
      />
      <path
        style={{
          fill: "#11e4ea",
          strokeWidth: "0px",
        }}
        d="M731.8,309.8c3,3.9,8.7,4.7,12.6,1.6,3.9-3,4.7-8.7,1.6-12.6l-45.8-59.3c-3-3.9-8.7-4.7-12.6-1.6-2.3,1.8-3.5,4.4-3.5,7.1s.6,3.9,1.9,5.5l45.8,59.3Z"
      />
      <path
        style={{
          fill: "#11e4ea",
          strokeWidth: "0px",
        }}
        d="M742.8,239.5c-3-3.9-8.7-4.7-12.6-1.6-2.3,1.8-3.5,4.4-3.5,7.1s.6,3.9,1.9,5.5l22.9,29.6c3,3.9,8.7,4.7,12.6,1.6,3.9-3,4.7-8.7,1.6-12.6l-22.9-29.6Z"
      />
      <path
        style={{
          fill: "#11e4ea",
          strokeWidth: "0px",
        }}
        d="M786.6,241.5c-.2-.5-.5-1.1-.8-1.5-.3-.5-.7-1-1.1-1.4-.4-.4-.9-.8-1.4-1.1-.5-.3-1-.6-1.6-.8-.5-.2-1.1-.4-1.7-.5-.6-.1-1.2-.2-1.8-.2s-1.2,0-1.8.2c-.6.1-1.1.3-1.7.5-.5.2-1.1.5-1.5.8-.5.3-1,.7-1.4,1.1-.4.4-.8.9-1.1,1.4-.3.5-.6,1-.8,1.5-.2.6-.4,1.1-.5,1.7-.1.6-.2,1.2-.2,1.8s0,1.2.2,1.8c.1.6.3,1.1.5,1.7.2.6.5,1.1.8,1.6.3.5.7.9,1.1,1.4.4.4.9.8,1.4,1.1.5.3,1,.6,1.5.8.6.2,1.1.4,1.7.5.6.1,1.2.2,1.8.2s1.2,0,1.8-.2c.6-.1,1.1-.3,1.7-.5.6-.2,1.1-.5,1.6-.8.5-.3,1-.7,1.4-1.1.4-.4.8-.9,1.1-1.4.3-.5.6-1,.8-1.6.2-.5.4-1.1.5-1.7.2-1.2.2-2.4,0-3.5-.1-.6-.3-1.1-.5-1.7Z"
      />
      <path
        style={{
          fill: "#11e4ea",
          strokeWidth: "0px",
        }}
        d="M624.2,336.1l-22,28.5s0,0,0,0l-.9,1.1c-3,3.9-2.3,9.6,1.6,12.6,3.9,3,9.6,2.3,12.6-1.6l18-23.3,9.1-11.8c.1-.2.2-.3.3-.5.2-.3.4-.5.5-.8.1-.3.2-.5.3-.8.1-.3.2-.5.3-.8,0-.3.1-.6.2-.9,0-.3.1-.5.1-.8,0-.3,0-.6,0-.9,0-.3,0-.6,0-.9,0-.3,0-.6-.1-.8,0-.3-.1-.6-.2-.9,0-.3-.2-.5-.3-.8-.1-.3-.2-.5-.3-.8-.1-.3-.3-.5-.5-.8-.1-.2-.2-.3-.3-.5l-26.3-34c-3-3.9-8.7-4.7-12.6-1.6-2.3,1.8-3.5,4.4-3.5,7.1s.6,3.9,1.9,5.5l22,28.5Z"
      />
      <path
        style={{
          fill: "#11e4ea",
          strokeWidth: "0px",
        }}
        d="M666.8,336.1l-45.4,58.7s0,0,0,0l-.4.5c-3,3.9-2.3,9.6,1.6,12.6,3.9,3,9.6,2.3,12.6-1.6l5.9-7.6,44.2-57.2c.1-.2.2-.3.3-.5.2-.3.4-.5.5-.8.1-.3.2-.5.3-.8.1-.3.2-.5.3-.8,0-.3.1-.6.2-.9,0-.3.1-.6.1-.8,0-.3,0-.6,0-.9s0-.6,0-.9c0-.3,0-.6-.1-.8,0-.3-.1-.6-.2-.9,0-.3-.2-.6-.3-.8-.1-.3-.2-.5-.3-.8-.2-.3-.3-.6-.5-.8-.1-.2-.2-.3-.3-.5l-49.6-64.2c-3-3.9-8.7-4.7-12.6-1.6-2.3,1.8-3.5,4.4-3.5,7.1s.6,3.9,1.9,5.5l45.4,58.7Z"
      />
      <path
        style={{
          fill: "#11e4ea",
          strokeWidth: "0px",
        }}
        d="M635.2,458.9c-3-3.9-8.7-4.7-12.6-1.6-3.9,3-4.7,8.7-1.6,12.6l45.8,59.3c3,3.9,8.7,4.7,12.6,1.6,2.3-1.8,3.5-4.4,3.5-7.1s-.6-3.9-1.9-5.5l-45.8-59.3Z"
      />
      <path
        style={{
          fill: "#11e4ea",
          strokeWidth: "0px",
        }}
        d="M615.5,488.5c-3-3.9-8.7-4.7-12.6-1.6-3.9,3-4.7,8.7-1.6,12.6l22.9,29.6c3,3.9,8.7,4.7,12.6,1.6,2.3-1.8,3.5-4.4,3.5-7.1s-.6-3.9-1.9-5.5l-22.9-29.6Z"
      />
      <path
        style={{
          fill: "#11e4ea",
          strokeWidth: "0px",
        }}
        d="M597,520.2c-.2-.6-.5-1.1-.8-1.6-.3-.5-.7-.9-1.1-1.4-.4-.4-.9-.8-1.4-1.1-.5-.3-1-.6-1.5-.8-.6-.2-1.1-.4-1.7-.5-.6-.1-1.2-.2-1.8-.2s-1.2,0-1.8.2c-.6.1-1.1.3-1.7.5-.6.2-1.1.5-1.6.8-.5.3-1,.7-1.4,1.1-.4.4-.8.9-1.1,1.4-.3.5-.6,1-.8,1.6-.2.5-.4,1.1-.5,1.7-.2,1.2-.2,2.4,0,3.5.1.6.3,1.1.5,1.7.2.5.5,1.1.8,1.5.3.5.7,1,1.1,1.4.4.4.9.8,1.4,1.1l3.2,1.3c.6.1,1.2.2,1.8.2s1.2,0,1.8-.2c.6-.1,1.1-.3,1.7-.5.5-.2,1.1-.5,1.5-.8.5-.3,1-.7,1.4-1.1.4-.4.8-.9,1.1-1.4.3-.5.6-1,.8-1.5.2-.6.4-1.1.5-1.7.1-.6.2-1.2.2-1.8s0-1.2-.2-1.8c-.1-.6-.3-1.1-.5-1.7Z"
      />
      <path
        style={{
          fill: "#11e4ea",
          strokeWidth: "0px",
        }}
        d="M764.8,404.1l.9-1.1c3-3.9,2.3-9.6-1.6-12.6-3.9-3-9.6-2.3-12.6,1.6l-19.3,25-7.9,10.2c-.1.2-.2.3-.3.5-.2.3-.4.5-.5.8-.1.3-.2.5-.3.8-.1.3-.2.5-.3.8,0,.3-.1.6-.2.9,0,.3-.1.5-.1.8,0,.3,0,.6,0,.9,0,.3,0,.6,0,.9,0,.3,0,.6.1.8,0,.3.1.6.2.9,0,.3.2.5.3.8.1.3.2.5.3.8.1.3.3.5.5.8.1.2.2.3.3.5l26.3,34c3,3.9,8.7,4.7,12.6,1.6,2.3-1.8,3.5-4.4,3.5-7.1s-.6-3.9-1.9-5.5l-22-28.5,22-28.5s0,0,0,0Z"
      />
      <path
        style={{
          fill: "#11e4ea",
          strokeWidth: "0px",
        }}
        d="M700.2,432.6l45.4-58.7s0,0,0,0l.4-.5c3-3.9,2.3-9.6-1.6-12.6-3.9-3-9.6-2.3-12.6,1.6l-7.7,9.9-42.4,54.8c-.1.2-.2.3-.3.5-.2.3-.4.5-.5.8-.1.3-.2.5-.3.8-.1.3-.2.5-.3.8,0,.3-.1.6-.2.9,0,.3-.1.6-.1.8,0,.3,0,.6,0,.9s0,.6,0,.9c0,.3,0,.6.1.8,0,.3.1.6.2.9,0,.3.2.6.3.8.1.3.2.5.3.8.2.3.3.6.5.8.1.2.2.3.3.5l49.6,64.2c3,3.9,8.7,4.7,12.6,1.6,2.3-1.8,3.5-4.4,3.5-7.1s-.6-3.9-1.9-5.5l-45.4-58.7Z"
      />
      <path
        style={{
          fill: "#11e4ea",
          strokeWidth: "0px",
        }}
        d="M657.5,432.6l66.1-85.6s0,0,0,0l4.3-5.5c.1-.2.2-.3.3-.5.2-.3.4-.5.5-.8.1-.3.2-.5.3-.8.1-.3.2-.5.3-.8,0-.3.1-.6.2-.9,0-.3.1-.6.1-.8,0-.3,0-.6,0-.9s0-.6,0-.9c0-.3,0-.6-.1-.8,0-.3-.1-.6-.2-.9,0-.3-.2-.6-.3-.8-.1-.3-.2-.5-.3-.8-.2-.3-.3-.6-.5-.8-.1-.2-.2-.3-.3-.5l-70.4-91.1c-3-3.9-8.7-4.7-12.6-1.6-2.3,1.8-3.5,4.4-3.5,7.1s.6,3.9,1.9,5.5l66.1,85.6-66.1,85.6s0,0,0,0l-4.3,5.5c-.1.2-.2.3-.3.5-.2.3-.4.5-.5.8-.1.3-.2.5-.3.8-.1.3-.2.5-.3.8,0,.3-.1.6-.2.9,0,.3-.1.6-.1.8,0,.3,0,.6,0,.9s0,.6,0,.9c0,.3,0,.6.1.8,0,.3.1.6.2.9,0,.3.2.6.3.8.1.3.2.5.3.8.2.3.3.6.5.8.1.2.2.3.3.5l70.4,91.1c3,3.9,8.7,4.7,12.6,1.6,2.3-1.8,3.5-4.4,3.5-7.1s-.6-3.9-1.9-5.5l-66.1-85.6Z"
      />
      <path
        style={{
          fill: "#11e4ea",
          strokeWidth: "0px",
        }}
        d="M786.6,423.7c-.2-.6-.5-1.1-.8-1.6-.3-.5-.7-.9-1.1-1.4-.4-.4-.9-.8-1.4-1.1-.5-.3-1-.6-1.5-.8-.6-.2-1.1-.4-1.7-.5-.6-.1-1.2-.2-1.8-.2s-1.2,0-1.8.2c-.6.1-1.1.3-1.7.5-.6.2-1.1.5-1.6.8-.5.3-1,.7-1.4,1.1-.4.4-.8.9-1.1,1.4-.3.5-.6,1-.8,1.6-.2.5-.4,1.1-.5,1.7-.2,1.2-.2,2.4,0,3.5.1.6.3,1.1.5,1.7.2.5.5,1.1.8,1.5.3.5.7,1,1.1,1.4.4.4.9.8,1.4,1.1l3.2,1.3c.6.1,1.2.2,1.8.2s1.2,0,1.8-.2c.6-.1,1.1-.3,1.7-.5.5-.2,1.1-.5,1.5-.8.5-.3,1-.7,1.4-1.1.4-.4.8-.9,1.1-1.4.3-.5.6-1,.8-1.5.2-.6.4-1.1.5-1.7.1-.6.2-1.2.2-1.8s0-1.2-.2-1.8c-.1-.6-.3-1.1-.5-1.7Z"
      />
    </svg>
  )
}

export default memo(BookLogo)
